import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import ERC20Abi from './contractsABI/ERC20.json';
import usdtTokenAbi from './contractsABI/abiTokenUSDT.json';
import lockerAbi from './contractsABI/Locker.json';
import presaleAbi from './contractsABI/Presale.json';
import presaleTestAbi from './contractsABI/PresaleTest.json';

const BNB_ADDRESS = '0x0000000000000000000000000000000000000000';

const ACQUIRING_SELL_GAS = '300000';

const TRANSACTION_BNB_VALUE = '0';

const TEST_PRICE_USDT = '10000000000000000';
const NANO_DECIMAL = 9;
const ETHER_DECIMAL = 18;
const MAXINMUM_CALLBACK_TRIES = 3;
const RPC_REQUEST_TIMEOUT = 4000;
const SETTINGS_KEYS = [
  'VIDI_TOKEN_ADDRESS',
  'LOCKER_ADDRESS',
  'PRESALE_ADDRESS',
  'USDT_ADDRESS',
  'WITHDRAW_GAS_VALUE',
  'BUY_LOCATION_GAS_VALUE',
  'RPC_NODES',
  'CHAIN_ID',
  'SEED_PRICE_USDT',
  'PRIVATE_PRICE_USDT',
  'PROJECT_ID',
];
const PRESALE_PUBLIC_VARIABLES = [
  'startTime',
  'remainingSeedCount',
  'remainingPrivateCount',
  'seedStart',
  'seedEnd',
  'privateStart',
  'privateEnd',
];
const checkSettings = (settings) => {
  SETTINGS_KEYS.forEach((item) => {
    if (!settings[item]) {
      throw new Error(`${item} property is missing from settings`);
    }
  });
};

class BlockChainError {
  constructor(message, transactionHash) {
    this.message = message;
    this.transactionHash = transactionHash;
  }
}
class Web3Component {
  constructor(settings) {
    checkSettings(settings);
    this.ERC20Abi = ERC20Abi;
    this.lockerAbi = lockerAbi;
    this.presaleAbi = presaleAbi;
    this.vidiTokenAddress = settings.VIDI_TOKEN_ADDRESS;
    this.lockerAddress = settings.LOCKER_ADDRESS;
    this.presaleAddress = settings.PRESALE_ADDRESS;
    this.usdtAddress = settings.USDT_ADDRESS;
    this.withdrawGasValue = settings.WITHDRAW_GAS_VALUE;
    this.buyLocationGasValue = settings.BUY_LOCATION_GAS_VALUE;
    this.rpcNodes = settings.RPC_NODES;
    this.chainId = settings.CHAIN_ID;
    this.networkIndex = 0;
    this.blocksToRead = settings.BLOCKS_TO_READ;
    this.seedPriceUsdt = settings.SEED_PRICE_USDT;
    this.privatePriceUsdt = settings.PRIVATE_PRICE_USDT;
    this.projectId = settings.PROJECT_ID;
  }

  _checkAccount() {
    if (!this.account || !this.web3) {
      throw new Error('wallet was not connected');
    }
  }

  _checkLockerAddress() {
    if (!this.lockerAddress) {
      throw new Error('Locker address is not specified');
    }
  }

  _checkNetworkIndex() {
    if (this.networkIndex === this.rpcNodes.length) {
      this.networkIndex = 0;
    }
  }

  static isConnected() {
    const walletConnect = JSON.parse(localStorage.getItem('walletconnect'));
    return walletConnect != null && walletConnect.connected;
  }

  async _sendToRpc(callback, type, ...args) {
    return this._sendToRpcCall(callback, type, 1, ...args);
  }

  async _sendToRpcCall(callback, type = null, numberOfSendRpcTry = 1, ...args) {
    try {
      const fn = callback.apply(this, args);
      if (type === 'call') {
        return await new Promise((resolve, reject) => {
          setTimeout(() => {
            const err = new Error('RPC does not respond for a very long time');
            err.timeout = true;
            reject(err);
          }, RPC_REQUEST_TIMEOUT);
          fn.call().then((data) => {
            resolve(data);
          }).catch((err) => {
            reject(err);
          });
        });
      }
      if (type === 'unlock' || type === 'buy') {
        return await fn.send({
          from: this.account,
          gas: type === 'unlock' ? this.withdrawGasValue : this.buyLocationGasValue,
          value: TRANSACTION_BNB_VALUE,
        });
      }
      return fn;
    } catch (error) {
      if (error.timeout) {
        throw new Error(error.message);
      }
      if (error.message) {
        if (error.message.startsWith('User rejected the transaction')) {
          throw new Error(error);
        } else if (error.message.startsWith('JSON-RPC success response must include "result" field')) {
          throw new Error('Wallet did not return the response');
        } else if (error.message.startsWith('Transaction has been reverted by the EVM')) {
          throw new BlockChainError('Error from blockchain', error.receipt.transactionHash);
        }
      }
      if (numberOfSendRpcTry < MAXINMUM_CALLBACK_TRIES) {
        return this._sendToRpcCall(callback, type, numberOfSendRpcTry + 1, ...args);
      }
      throw new Error(error);
    }
  }

  /**
   * Подключение крипто-кошелька пользователя
   * @returns {boolean} true если подключился
   */
  async connectWallet() {
    this.provider = await EthereumProvider.init({
      chains: [this.chainId],
      projectId: this.projectId,
      showQrModal: true,
    });
    await this.provider.enable();
    const web3 = new Web3(this.provider);
    this.web3 = web3;
    const accounts = await web3.eth.getAccounts();
    [this.account] = accounts;
    return true;
  }

  /**
   * Получение адреса кошелька пользователя
   * @returns {string} Адрес кошелька пользователя
   */
  getAccount() {
    return this.account;
  }

  /**
   * Получение провайдера wallet-connect
   * @returns {Object} Объекто провайдера
   */
  getProvider() {
    return this.provider;
  }

  /**
   * Отключение коешлька пользователя
   * @returns {void}
   */
  disconnectWallet() {
    if (!this.provider) return true;
    this.provider.disconnect();
    return true;
  }

  /**
   * Получение баланса пользователя по токену
   * @param {string} tokenAddress Адрес токена
   * @returns {string} Баланс
   */
  async getTokenBalance(tokenAddress = this.vidiTokenAddress) {
    this._checkAccount();
    if (!this.web3.utils.isAddress(tokenAddress)) {
      throw new Error('Token address is not valid');
    }
    let balance = 0;
    let decimal = ETHER_DECIMAL;
    if (tokenAddress === BNB_ADDRESS) {
      balance = await this._sendToRpc(this.web3.eth.getBalance, null, this.account);
    } else {
      const contract = new this.web3.eth.Contract(this.ERC20Abi, tokenAddress);
      balance = await this._sendToRpc(contract.methods.balanceOf, 'call', this.account);
      if (tokenAddress !== this.vidiTokenAddress) {
        decimal = await this._sendToRpc(contract.methods.decimals, 'call');
      }
    }
    const unit = decimal === NANO_DECIMAL ? 'nano' : 'ether';
    const balanceInEth = this.web3.utils.fromWei(balance, unit);
    return balanceInEth;
  }

  /**
   * @typedef {Object} Lock
   * @property {number} cliff - Время ожидания, перед началом разблокировки
   * @property {number} startDate - Время начала разблокировки
   * @property {number} duration - Время, за которое произойдет весь разлок
   * @property {number} slicePeriod - Промежуток, за который произойдет один раздок
   * @property {string} amount - Сумма изначально заблокированных токенов
   * @property {string} released - Сумма выведенных токенов
   * @property {string} releasableAmount - Сумма доступных к выводу токенов
   */
  /**
   * Получение всех локов пользователя
   * @returns {Lock[]} Массив локов
   */
  async getUserLocks() {
    this._checkLockerAddress();
    this._checkAccount();
    const contract = new this.web3.eth.Contract(this.lockerAbi, this.lockerAddress);
    const locksCount = await this._sendToRpc(contract.methods
      .getVestingSchedulesCountByBeneficiary, 'call', this.account);
    const countArray = [...Array(Number(locksCount)).keys()];
    const promises = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const lockIndex of countArray) {
      promises.push(this._sendToRpc(contract.methods
        .getVestingScheduleByAddressAndIndex, 'call', this.account, lockIndex));
    }

    const _locks = await Promise.all(promises);

    const locks = _locks.map((lock, index) => ({ index, ...lock }));
    return locks.map((lock) => {
      const cliff = Number(lock.cliff) - (Number(lock.start));
      return {
        index: lock.index,
        cliff,
        startDate: Number(lock.cliff),
        duration: Number(lock.duration),
        slicePeriod: Number(lock.slicePeriodSeconds),
        amount: this.web3.utils.fromWei(lock.amountTotal, 'ether'),
        released: this.web3.utils.fromWei(lock.released, 'ether'),
        releasableAmount: this._computeReleasableAmount(lock),
      };
    });
  }

  /**
   * Вывод разблокированных токенов
   * @param {number} lockIndex Индекс лока в массиве локов пользователя
   * @returns {string} ID транзакции
   */
  async withdrawLockedTokens(lockIndex) {
    if (lockIndex === undefined) {
      throw new Error('Lock is not specified');
    }
    if (typeof lockIndex !== 'number') {
      throw new Error('Lock index should be a number');
    }
    this._checkLockerAddress();
    this._checkAccount();
    const contract = new this.web3.eth.Contract(this.lockerAbi, this.lockerAddress);
    const lockId = await new Promise((resolve) => {
      this._sendToRpc(contract.methods
        .computeVestingScheduleIdForAddressAndIndex, 'call', this.account, lockIndex)
        .then((data) => resolve(data))
        .catch(() => {
          throw new Error('This address does not have a token with such an index');
        });
    });

    const releasableAmount = await this._sendToRpc(contract.methods
      .computeReleasableAmount, 'call', lockId);
    if (+releasableAmount < 1) {
      throw new Error('No unlock tokens available for withdraw in this lock');
    }
    const transaction = await this._sendToRpc(contract.methods.release, 'unlock', lockId, releasableAmount);
    return transaction.transactionHash;
  }

  /**
   * @typedef {Object} Transaction
   * @property {string} type - Тип транзакции
   * @property {string} amount - Сумма транзакции
   * @property {string} from - Адрес отправителя
   * @property {string} to - Адрес получателя
   * @property {string} transactionHash - Хеш транзакции
   */
  /**
   * Получение списка транзакций
   * @returns {Transaction[]} Список транзакций
   */
  async getTransactions() {
    const contract = new this.web3.eth.Contract(this.ERC20Abi, this.vidiTokenAddress);
    const currentBlock = await this._sendToRpc(this.web3.eth.getBlockNumber);
    const fromBlock = currentBlock - this.blocksToRead;
    const transactions = await contract.getPastEvents('Transfer', {
      filter: [{ from: this.account }, { to: this.account }],
      fromBlock,
      toBlock: 'latest',
    });
    const renderTransactions = transactions.map((transaction) => {
      const { returnValues } = transaction;
      return {
        transactionHash: transaction.transactionHash,
        type: returnValues.from === this.account ? 'out' : 'in',
        from: returnValues.from,
        to: returnValues.to,
        amount: this.web3.utils.fromWei(returnValues.value, 'ether'),
      };
    });
    return renderTransactions;
  }

  /**
   * Расчет суммы доступной к выводу
   * @returns {string} Сумма доступная к выводу
   */
  _computeReleasableAmount(vestingSchedule) {
    const currentTime = Math.floor(Date.now() / 1000);
    let amount = 0;
    if ((currentTime < vestingSchedule.cliff) || vestingSchedule.revoked) {
      return '0';
    } if (currentTime >= Number(vestingSchedule.start) + (Number(vestingSchedule.duration))) {
      amount = new BigNumber(vestingSchedule.amountTotal).minus(vestingSchedule.released);
    } else {
      const timeFromStart = new BigNumber(currentTime).minus(vestingSchedule.start);
      const secondsPerSlice = vestingSchedule.slicePeriodSeconds;
      const vestedSlicePeriods = timeFromStart
        .dividedBy(secondsPerSlice)
        .integerValue(BigNumber.ROUND_DOWN);
      const vestedSeconds = vestedSlicePeriods.multipliedBy(secondsPerSlice);
      const vestedAmount = new BigNumber(vestingSchedule.amountTotal)
        .multipliedBy(vestedSeconds)
        .dividedBy(vestingSchedule.duration);
      amount = vestedAmount.minus(vestingSchedule.released);
    }
    return this.web3.utils.fromWei(amount.integerValue(BigNumber.ROUND_DOWN).toFixed(0), 'ether');
  }

  async checkAllowance(needAllowance, contract = this.ERC20Abi, address = this.usdtAddress) {
    const liquidityToken = new this.web3.eth.Contract(contract, address);
    const allowance = await this._sendToRpc(liquidityToken.methods.allowance, 'call', this.account, this.presaleAddress);
    if (Number(allowance) < Number(needAllowance)) {
      const transaction = await this._sendToRpc(liquidityToken.methods.approve, 'unlock', this.presaleAddress, needAllowance);
      return transaction.transactionHash;
    }
    return true;
  }

  /**
   * Покупка локации Private разблокированных токенов
   * @returns {string} ID транзакции
   */
  async buyPrivate() {
    this._checkAccount();
    await this.checkAllowance(this.privatePriceUsdt);
    const contract = new this.web3.eth.Contract(this.presaleAbi, this.presaleAddress);
    const transaction = await this._sendToRpc(contract.methods
      .buyPrivate, 'buy');
    return transaction.transactionHash;
  }

  /**
   * Покупка локации Seed разблокированных токенов
   * @returns {string} ID транзакции
   */
  async buySeed() {
    this._checkAccount();
    await this.checkAllowance(this.seedPriceUsdt);
    const contract = new this.web3.eth.Contract(this.presaleAbi, this.presaleAddress);
    const transaction = await this._sendToRpc(contract.methods
      .buySeed, 'buy');
    return transaction.transactionHash;
  }

  /**
   * Тестовая функция для тестирования перевода в 0.01 USDT
   * @returns {string} ID транзакции
   */
  async buyTest() {
    this._checkAccount();
    await this.checkAllowance(TEST_PRICE_USDT);
    const contract = new this.web3.eth.Contract(presaleTestAbi, this.presaleAddress);
    const transaction = await this._sendToRpc(contract.methods
      .buyTest, 'buy');
    return transaction.transactionHash;
  }

  /**
   * Вывод разблокированных токенов
   * @param {number} variableName Имя переменной в смарт контракте
   * @returns {string} Значение переменной
   */
  async getPublicVariable(variableName) {
    if (!variableName) {
      throw new Error('Variable is not specified');
    }
    if (!PRESALE_PUBLIC_VARIABLES.includes(variableName)) {
      throw new Error(`Variable ${variableName} is not exist`);
    }
    const sendToRpcPublic = async (tryNumber) => {
      try {
        this._checkNetworkIndex();
        if (tryNumber <= MAXINMUM_CALLBACK_TRIES) {
          const publicWeb3 = new Web3(this.rpcNodes[this.networkIndex]);
          const contract = new publicWeb3.eth.Contract(this.presaleAbi, this.presaleAddress);
          const variable = await this._sendToRpc(contract.methods[variableName], 'call');
          return variable;
        }
        throw new Error('It is impossible to get a variable, 3 attempts are exhausted');
      } catch (error) {
        this.networkIndex += 1;
        return sendToRpcPublic(tryNumber + 1);
      }
    };
    return sendToRpcPublic(1);
  }

  async transferW2W(receiver, amount) {
    await this.checkAllowance(amount);
    const contract = new this.web3.eth.Contract(usdtTokenAbi, this.usdtAddress);
    const _amount = this.web3.utils.toWei(amount, 'ether');
    const sendingObj = {
      from: this.getAccount(),
      gas: ACQUIRING_SELL_GAS,
      amount: _amount,
    };

    const [transaction] = await Promise.all([contract.methods
      .transfer(receiver, _amount)
      .send(sendingObj, (err, res) => err || res)]);

    return transaction;
  }
}

window.web3Component = Web3Component;
export default Web3Component;
